export const  subjects = [
    {id:1,title:'Administration'},
    {id:2,title:'Agriculture'},
    {id:3,title:'Artificial Intelligence'},
    {id:4,title:'Banking & business'},
    {id:5,title:'Biology'},
    {id:6,title:'Chemistry'},
    {id:7,title:'Commerce & industry'},
    {id:8,title:'Economic'},
    {id:9,title:'Engineering & construction'},
    {id:10,title:'Environment'},
    {id:11,title:'Finance & taxation'},
    {id:12,title:'Foreign and diplomacy'},
    {id:13,title:'Geography'},
    {id:14,title:'Health'},
    {id:15,title:'Justice & courts'},
    {id:16,title:'Labour & TVT'},
    {id:17,title:'Law'},
    {id:18,title:'Livestock'},
    {id:19,title:'Local Municipality'},
    {id:20,title:'Mathematics'},
    {id:21,title:'Media & journalism'},
    {id:22,title:'NGO & Project management'},
    {id:23,title:'Oil & Energy'},
    {id:24,title:'Parliamentery '},
    {id:25,title:'Physics'},
    {id:26,title:'Planning'},
    {id:27,title:'Police'},
    {id:28,title:'Port, maritime & fishing'},
    {id:29,title:'Religion & charity'},
    {id:30,title:'Sports'},
    {id:31,title:'Statistics'},
    {id:32,title:'Technology & internet'},
    {id:33,title:'Tourism - food & kitchen ingredient'},
    {id:34,title:'Transportation & logistics'},
    {id:35,title:'Water'},
]

















