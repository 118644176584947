import React from 'react'
import {Routes,Route} from 'react-router-dom'
import HomeScreen from 'container/home-screen'
import Pricing from 'container/pricing'
import Aboutus from 'container/about-us'
import Reviews from 'container/reviews'
import Contact from 'component/contact'
import Faq from 'container/faq'
import Bloglist from 'container/blog-list'
import Blogsingle from 'container/blog-single'
const HeaderRoutes = () => {
  return (
  <>
  <Routes>
    <Route path='/' element={<HomeScreen/>} />
    {/* <Route path='/features' element={<FeaturesScreen/>} /> */}
    <Route path='/about-us' element={<Aboutus/>} />
    {/* <Route path='/review' element={<Reviews/>} /> */}
    <Route path='/contact' element={<Contact/>} />
    {/* <Route path='/faq'  element={<Faq/>} /> */}
    {/* <Route path='/blog-list' element={<Bloglist/>}  /> */}
    {/* <Route path='/blog-single' element={<Blogsingle/>}  /> */}
    {/* <Route path='/pricing' element={<Pricing/>} /> */}
  </Routes>
  </>
  )
}

export default HeaderRoutes
