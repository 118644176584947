import styled from 'styled-components'
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main=styled.div`

.row_am {
    padding: 50px 0;
}

/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}

/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* Blog Detail Css Start */
.blog_detail_section {
  margin-bottom: 40px;
}

.blog_detail_bredcrumb.bred_crumb .bred_text {
  margin-top: -30px;
}

.blog_detail_section .blog_inner_pannel {
  background-color: #FFFFFF;
  border-radius: 30px;
  padding: 45px 60px;
  margin-top: -120px;
  position: relative;
  box-shadow: 0 8px 30px #c0bad76b;
}

.blog_detail_section .blog_inner_pannel .review {
  display: flex; 
  justify-content: center;
  margin-bottom: 15px;
}

.blog_detail_section .blog_inner_pannel .review span {
  line-height: 1;
}

.blog_detail_section .blog_inner_pannel .review span:first-child {
  color: var(--purple);
  display: inline-block;
  font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .review span:last-child {
  color: var(--dark-purple);
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid var(--dark-purple);
}

.blog_detail_section .blog_inner_pannel .section_title {
  margin-bottom: 40px;
}

.blog_detail_section .blog_inner_pannel .section_title h2 {
  margin-bottom: 0;
}

.blog_detail_section .blog_inner_pannel img {
  max-width: 100%;
}

.blog_detail_section .blog_inner_pannel .main_img {
  margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .info h3 {
  font-weight: 700;
  color: var(--dark-purple);
  margin-top: 30px;
  font-size: 25px;
  margin-bottom: 15px;
}

.blog_detail_section .blog_inner_pannel .info ul {
  padding-left: 50px;
  padding-top: 10px;
  margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .info ul li p {
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
}

.blog_detail_section .blog_inner_pannel .info ul li p .icon {
  position: absolute;
  left: 0;
  color: var(--purple);
}

.blog_detail_section .blog_inner_pannel .two_img {
  margin-bottom: 70px;
}

.blog_detail_section .blog_inner_pannel .quote_block {
  background-color: #F6F4FE;
  border-radius: 12px;
  padding: 55px 35px 35px 60px;
  position: relative;
  margin-top: 50px;
  margin-bottom: 35px;
}

.blog_detail_section .blog_inner_pannel .quote_block h2 {
  font-size: 25px;
  line-height: 37px;
}

.blog_detail_section .blog_inner_pannel .quote_block p {
  margin-bottom: 0;
  margin-top: 15px;
}

.blog_detail_section .blog_inner_pannel .quote_block .name {
  color: var(--purple);
  font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon {
  position: absolute;
  top: -20px;
  width: 63px;
  height: 63px;
  background-color: var(--bg-white);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon img {
  width: 35px;
}

.blog_detail_section .blog_inner_pannel .blog_authore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-top: 1px solid #D6D3E3;
  border-bottom: 1px solid #D6D3E3;
  margin: 35px 0;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text {
  margin-left: 20px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-purple);
  margin-bottom: 5px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text span {
  font-size: 14px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul {
  display: flex;
}

/* Social Media link list */
.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li a {
  display: block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 15px;
  border: 1px solid #CCC9D7;
  border-radius: 50px;
  margin-right: 10px;
  transition: .4s all;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li:last-child a {
  margin-right: 0;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li a:hover {
  background-color: var(--bg-purple);
  color: var(--text-white);
}

.blog_detail_section .blog_inner_pannel .blog_tags ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul .tags p {
  margin-bottom: 0;
  color: var(--dark-purple);
  font-weight: 500;
  margin-right: 5px;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul li span {
  color: var(--purple);
  font-weight: 500;
}


/* comment section Css Start */
.comment_section ul {
  margin-top: 30px;
}

.comment_section ul li {
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #D6D3E3;
}

.comment_section ul li:last-child {
  padding-bottom: 0;
}

.comment_section ul li.replay_comment {
  margin-left: 110px;
}

.comment_section ul li .authore_info {
  display: flex;
  align-items: center;
  width: 260px;
}

.comment_section ul li .authore_info .avtar {
  width: 88px;
  margin-right: 20px;
}

.comment_section ul li .authore_info .text {
  width: calc(100% - 108px);
}

.comment_section ul li .authore_info .text h4 {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-purple);
}

.comment_section ul li .authore_info .text span {
  font-size: 14px;
}

.comment_section ul li .comment {
  width: calc(100% - 310px);
  margin-left: 50px;
}

.comment_section ul li .comment p {
  margin-bottom: 0;
}
/* comment-form-section */

.comment_form_section form {
  margin-top: 30px;
}

.comment_form_section form .form-group .form-control {
  border-radius: 12px;
  height: 60px;
  box-shadow: 0 3px 6px #EDE9FE;
  border: transparent;
  margin-bottom: 30px;
  color: var(--body-text-purple);
  padding: 15px;
}

.comment_form_section form .form-group .form-control::placeholder {
  color: var(--body-text-purple);
}

.comment_form_section form .form-group textarea.form-control {
  height: 140px;
  padding-top: 15px;
  resize: none;
}

/* ---------------Latest_Story-Css-Start------------- */

/* latest story wraper */
.latest_story {
    margin-bottom: 80px;
}

/* latest story box */
.latest_story .story_box {
    background-color: var(--bg-white);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 30px #EDE9FE; 
}

/* latest story image */
.latest_story .story_box .story_img {
    position: relative;
}

.latest_story .story_box .story_img img {
    max-width: 100%;
    border-radius: 12px;

}

.latest_story .story_box .story_img span {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 14px;
    color: var(--text-white);
}

/* latest story pargraph */
.latest_story .story_box .story_text {
    padding: 20px 30px;
}

/* latest story heading h3 */
.latest_story .story_box .story_text h3 {
    color: var(--dark-purple);
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

/* latest story link text */
.latest_story .story_box .story_text a {
    color: var(--purple);
    margin-top: 25px;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.latest_story .story_box .story_text a:hover {
    text-decoration:underline;
}

/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}



/* ------Media-Query-Start--------- */
@media screen and (max-width:1300px) {
    
    /* features section */
    .features_section .feature_detail .feature_box {width: 350px;}
    .features_section .feature_detail .left_data {padding-left: 75px;}
    .features_section .feature_detail .right_data {padding-right: 75px;}

    /* hero banner shape animation */
    .banner_section .banner_shape1 { top: 0px; left: -3%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }

    /* footer shape animation */
    .top_footer .banner_shape1 { top: 0px; left: -3%;}
    .top_footer .banner_shape2 { top: 100px; right: -2%; }
    .top_footer .banner_shape3 { top: 400px; right: -4%; }

}


@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    /* navigation bar section */
    .navbar-expand-lg .navbar-nav .nav-link {padding: 5px 8px; color: var(--text-white);}
    .navbar-expand-lg .navbar-nav .nav-link.dark_btn {padding: 9px 30px;}
    .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {right: 5px;}

    /* hero banner shape animation */
    .banner_section .banner_shape1 { top: -30px; left: -2%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }

    /* footer shape animation */
    .top_footer .banner_shape1 { top: -30px; left: -2%;}
    .top_footer .banner_shape2 { top: 100px; right: -2%; }
    .top_footer .banner_shape3 { top: 400px; right: -4%; }



    .banner_section .banner_slider .right_icon {right: -30px;}

    /* features section */
    .features_section .feature_detail .feature_box {width: 300px;}
    .features_section .feature_detail .left_data {padding-left: 15px;}
    .features_section .feature_detail .right_data {padding-right: 15px;}

    /* about app section */
    .about_app_section .about_text .app_statstic li {width: 210px; padding-left: 20px;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 30px;}

    /* modern ui section */
    .modern_ui_section .ui_images::before {width: 475px; height: 475px;}
    .modern_ui_section .ui_images .right_img img:nth-child(1) {margin-left: -60px;}
    .modern_ui_section .ui_images .right_img img:nth-child(2) {margin-left: -140px;}

    /* download app section */
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.8);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -120px;}
    .free_app_section .container .free_app_inner .free_img {margin-top: -180px;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}


    .youtube-video .modal-dialog  {max-width: 940px !important;}

    .app_solution_section .app_images ul li:nth-child(2) {width: 45%; left: 0;}
    .app_solution_section .app_images ul li:nth-child(3) {width: 50%; right: 0;}

    .experts_team_section .experts_box .text span {min-height: 50px; display: block; margin-bottom: 10px;}

    .review_list_section .review_box h3 {font-size: 16px;}
    .review_list_section .review_box p {font-size: 14px;}
    .review_list_section .review_box .reviewer .text span {font-size: 14px;}

    .contact_page_section .contact_inner {margin-top: -120px;}
    .contact_page_section .contact_inner .contact_form {width: 520px; padding: 50px 30px;}
    .contact_page_section .contact_inner .contact_info {width: 350px;}
    .contact_page_section .contact_inner .section_title h2 {font-size: 34px;}

    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

    .blog_detail_section .blog_inner_pannel {margin-top: -100px; padding: 30px;}

}

@media screen and (max-width:992px) {

    /* navigation section */
    .navbar-toggler-icon {display: flex; align-items: center; color: var(--body-text-purple); justify-content: flex-end;}
    .free_app_section .container .free_app_inner .free_text .section_title h2 , .section_title h2 {font-size: 30px;}
    header.fix_style {padding: 5px 0;}

    .banner_section {text-align: center; padding-top: 200px;}
    .banner_section .app_btn , .banner_section .used_app {justify-content: center;}

     /* navigation section */
    .navbar-expand-lg .navbar-nav {align-items: flex-start;}
    .navbar-toggler {padding-right: 0; line-height: 1.7;}
    .navbar-expand-lg .navbar-nav {padding: 20px 15px; background-color: var(--bg-white); border-radius: 15px; }
    .navbar-expand-lg .navbar-nav .nav-link { color: var(--body-text-purple);}
    .navbar-expand-lg .navbar-nav .nav-link.dark_btn {margin-left: 0; margin-top: 15px;}
    .navbar-expand-lg .navbar-nav .has_dropdown {flex-direction: column; align-items: flex-start; width: 100%; box-shadow: none;}
    .navbar-expand-lg .navbar-nav .has_dropdown:hover {box-shadow: none;}
    .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {position: absolute; right: 0; padding: 0 10px; color: var(--body-text-purple)}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {position: relative; opacity: 1; pointer-events: all; top: auto; background-color: transparent; width: 100%; border-bottom: 1px solid #6b49f268; border-radius: 0; width: 100%; min-width: 100%; max-width: 100%; margin-left: 10px; display: none; box-shadow: none; margin-top: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {padding: 0; margin-top: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {padding: 5px 10px; display: block; padding-left: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {margin-top: 0;}

    /* banner section */
    .banner_section .banner_text h1 {font-size: 40px;}
    .banner_section .banner_slider {margin-top: 50px;}
    .banner_section .banner_slider .left_icon {left: 0;}
    .banner_section .banner_slider .right_icon {right: 0;}

    .banner_section .banner_image {max-width: 100%; margin-top: 75px; text-align: center; display: inherit;}

    /* features section */
    .features_section .feature_detail .feature_img {width: 275px;}
    .features_section .feature_detail .feature_box .data_block {margin-bottom: 30px;}
    .features_section .feature_detail .feature_box {width: 200px;}

    /* about app section */
    .about_app_section .row {flex-direction: column-reverse;}
    .about_app_section .about_img {justify-content: flex-start; margin-top: 75px; margin-left: 0;}
    .about_app_section .about_img::before {left: 50%;}
    .about_app_section .about_img .screen_img {margin-left: -170px;}
    .about_app_section .about_text .app_statstic {justify-content: flex-start;}
    .about_app_section .about_text .app_statstic li {width: 35%; margin-right: 30px;}
    .about_app_section .about_img::before {left: 35%;}
    
    /* modern ui section */
    .modern_ui_section .ui_images {margin-top: 30px;}
    .modern_ui_section .ui_text {padding-right: 0;}
    .modern_ui_section .ui_images .right_img {display: flex; flex-direction: column; align-items: center;}

    /* how it work section */
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img {width: 280px;}
    .how_it_works .yt_video .thumbnil a {top: 57%;}
    .how_it_works .yt_video .thumbnil a span {font-size: 20px;}
    
    /* testimonial section */
    #testimonial_slider {max-width: 500px;}

    /* pricing section */
    .pricing_section .pricing_pannel .pricing_block {padding-left: 15px; padding-right: 15px; min-height: 600px; padding-top: 30px;}
    .pricing_section .pricing_pannel .pricing_block .price {font-size: 36px;}
    .pricing_section .pricing_pannel .pricing_block .benifits li p {font-size: 14px;}

    /* interface section */
    .interface_section .screen_slider {min-height: 550px;}

    /* download app section */
    .free_app_section .container .free_app_inner {padding: 20px 15px;}
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.7);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -180px;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a {padding: 10px 20px;}

    /* latest story section */
    .latest_story {margin-bottom: 40px;}
    .latest_story .story_box .story_text {padding: 15px;}

    /* newsletter section */
    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}

    /* footer section */
    footer .top_footer .logo , footer h3 {margin-bottom: 20px;}
    footer .abt_side {margin-bottom: 50px;}
    footer .top_footer .try_out {margin-left: 0;}
    footer .top_footer .col-md-6 {margin-bottom: 15px;}


    /* Bred Crumb */
    .bred_crumb {min-height: 350px;}
    .bred_crumb .bred_text h1 {font-size: 30px;}
    .bred_crumb::after {background-size: 100%; height: 90px; bottom: -5px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}

    .bred_crumb .banner_shape3 { top: 240px;   animation: mymove 3s infinite; }
    

    .app_solution_section .app_images {margin-top: 30px;}
    .app_solution_section .app_images ul li:nth-child(2) {text-align: right;}
    .app_solution_section .app_text p {padding-right: 0;}
    .app_solution_section .app_text .section_title {text-align: center;}
    .app_solution_section .app_images {margin-top: 70px;}
    .app_solution_section .app_images::before {width: 350px; height: 350px;}

    .why_we_section .why_inner {padding: 35px 0; padding-bottom: 0px;}
    .why_we_section .why_inner .why_box {margin-bottom: 50px; text-align: center;} 
    .why_we_section .why_inner .why_box .icon {margin-bottom: 30px;}
    

    .experts_team_section .experts_box .text span {min-height: auto;}
    .query_inner .white_btn {font-size: 14px;}

    
    .blog_list_main .blog_text {margin-top: 30px;}
    .blog_detail_section .blog_inner_pannel {margin-top: -100px; padding: 30px;}
    .blog_detail_section .blog_inner_pannel .info ul {padding-left: 30px;}
    .comment_section ul li .comment { width: calc(100% - 280px); margin-left: 20px;}
    .blog_detail_section .blog_inner_pannel .quote_block {padding: 55px 30px 35px 30px;}

    .contact_page_section .contact_inner {flex-direction: column; margin-top: 60px;}
    .contact_page_section .contact_inner .contact_form {width: 100%;}
    .contact_page_section .contact_inner .contact_info {width: 100%; margin-top: 50px;}

    

}

@media screen and (max-width:767px) {

    body {font-size: 14px; text-align: center;}
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}

    /* latest story section */
    .latest_story .story_box {margin-bottom: 30px;}

    /* newsletter section */
    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}

    /* latest story section */
    .latest_story .story_box .story_img img {width: 100%;}    

    .purple_backdrop {display: none;}


    /* Bread-Crumb */
    .bred_crumb {min-height: 330px;}
    .bred_crumb .bred_text h1 {font-size: 24px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
    .bred_crumb .bred_text {padding: 0 15px; margin-top: 15px;}
    .bred_crumb::after {background-size: cover; height: 100px;} 



    .blog_list_main .blog_text .section_title , .blog_list_story .story_box {text-align: center;}


    .pagination_block ul li:not(:first-child , :last-child) a {width: 24px; height: 24px; line-height: 24px; margin: 0 3px; font-size: 12px;}

    .blog_detail_bredcrumb.bred_crumb .bred_text {margin-top: -10px;}
    .blog_detail_section .blog_inner_pannel {margin-top: -70px; padding: 25px 20px;}
    .blog_detail_section .blog_inner_pannel .section_title {margin-bottom: 20px;}
    .blog_detail_section .blog_inner_pannel .section_title h2 {font-size: 22px;}
    .blog_detail_section .blog_inner_pannel .info h3 {font-size: 18px;}
    .blog_detail_section .blog_inner_pannel .info ul {text-align: left; padding-left: 0;}
    .blog_detail_section .blog_inner_pannel .two_img {margin-bottom: 0;}
    .blog_detail_section .blog_inner_pannel .two_img img {margin: 10px 0;}
    .blog_detail_section .blog_inner_pannel .quote_block .q_icon {left: 50%; transform: translateX(-50%);}
    .blog_detail_section .blog_inner_pannel .quote_block {padding: 55px 20px 35px 20px;}
    .blog_detail_section .blog_inner_pannel .quote_block h2 {font-size: 18px; line-height: 1.4;}
    .blog_detail_section .blog_inner_pannel .blog_authore {flex-direction: column;}
    .blog_detail_section .blog_inner_pannel .blog_authore .authore_info {margin-bottom: 30px;}
    .blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {font-size: 18px;}

    .comment_section ul li {flex-direction: column; padding: 20px 0; text-align: left;}
    .comment_section ul li .comment {width: 100%; margin-left: 0;}
    .comment_section ul li .authore_info {width: 100%; margin-bottom: 15px;}
    .comment_section ul li.replay_comment {margin-left: 30px;}
    .comment_section ul li .authore_info .text {width: auto;}

    .comment_form_section form .form-group .form-control {height: 45px; font-size: 14px; margin-bottom: 15px;}



}

@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}
`