import styled from "styled-components";
 export const Main=styled.div`
 
/* -----------Header-Css-Start------------------- */
/* header wraper */
header {
    position: absolute;
    width: 100%;
    z-index: 99999;
    transition: .4s all;
}

header.fix_style {
    position: fixed;
    top: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(106, 73, 242, 0.9);
    padding: 15px 0;
    transition: none;
    opacity: 0;
    pointer-events: none;
}

header.fixed {
   pointer-events: all;
   opacity: 1;
   transition: .4s all;
}

header.fixed .navbar {
    padding: 0;
}

/* navigation bar */
.navbar {
    padding-left: 0;
    padding-right: 0;
    padding-top: 35px;
}

.navbar-expand-lg .navbar-nav {
    align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 5px 20px;
    font-weight: 500;
    color: var(--text-white);
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--text-white);
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    color: var(--purple);
    background-color: var(--bg-white);
    font-size: 16px;
    padding: 9px 40px;
    border-radius: 25px;
    margin-left: 20px;
    position: relative;
    border: 1px solid var(--bg-purple);
}


.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before, 
.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 42px;
    z-index: -1;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
	animation: pulse-blue-medium-sm 3.5s infinite
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after  {
	animation: pulse-blue-small-sm 3.5s infinite
}

.navbar-brand img {
    width: 150px;
}

/* navigation bar dropdown */
.navbar-expand-lg .navbar-nav .has_dropdown {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px 10px 0 0;
    transition: .4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover {
    background-color: var(--bg-white);
    box-shadow: 0px 4px 10px #c5c5c580;
}

.navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    position: relative;
    right: 15px;
    color: var(--text-white);
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
    position: absolute;
    top: 100%;
    background-color: var(--bg-white);
    border-radius: 0 10px 10px 10px;
    min-width: 210px;
    max-width: 230px;
    margin-top: -10px;
    transition: .4s all;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 4px 10px #c5c5c580;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
    margin-left: 0;
    padding: 10px 20px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
    font-size: 15px;
    position: relative;
    transition: .4s all;
    line-height: 35px;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid var(--purple);
    border-radius: 10px;
    margin-right: 5px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: .4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover {
    padding-left: 15px;
    color: var(--purple);
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover::before {
    opacity: 1;
    left: 0;
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover>a,
.navbar-expand-lg .navbar-nav .has_dropdown:hover>.drp_btn {
    color: var(--purple);
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
    opacity: 1;
    pointer-events: all;
    margin-top: -1px;
}

/* navigation toggle menu */
.toggle-wrap {
    padding: 10px;
    position: relative;
    cursor: pointer;
    
    /*disable selection*/
    -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .toggle-bar,
  .toggle-bar::before,
  .toggle-bar::after,
  .toggle-wrap.active .toggle-bar,
  .toggle-wrap.active .toggle-bar::before,
  .toggle-wrap.active .toggle-bar::after {
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
  }

  .toggle-bar {
      width: 25px;
      margin: 10px 0;
      position: relative;
      border-top: 4px solid var(--bg-white);
      display: block;
  }

  .toggle-bar::before,
  .toggle-bar::after {
      content: "";
      display: block;
      background: var(--bg-white);
      height: 4px;
      width: 30px;
      position: absolute;
      top: -12px;
      right: 0px;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -ms-transform-origin: 13%;
      -webkit-transform-origin: 13%;
      transform-origin: 13%;
  }

  .toggle-bar::after {
    top: 4px;
  }

  .toggle-wrap.active .toggle-bar {
    border-top: 6px solid transparent;
  }

  .toggle-wrap.active .toggle-bar::before {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  .toggle-wrap.active .toggle-bar::after {
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }





  /* ------Media-Query-Start--------- */
@media screen and (max-width:1300px) {
    
    /* features section */
    .features_section .feature_detail .feature_box {width: 350px;}
    .features_section .feature_detail .left_data {padding-left: 75px;}
    .features_section .feature_detail .right_data {padding-right: 75px;}

    /* hero banner shape animation */
    .banner_section .banner_shape1 { top: 0px; left: -3%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }

    /* footer shape animation */
    .top_footer .banner_shape1 { top: 0px; left: -3%;}
    .top_footer .banner_shape2 { top: 100px; right: -2%; }
    .top_footer .banner_shape3 { top: 400px; right: -4%; }

}


@media screen and (max-width:1200px) {

    /* navigation bar section */
    .navbar-expand-lg .navbar-nav .nav-link {padding: 5px 8px; color: var(--text-white);}
    .navbar-expand-lg .navbar-nav .nav-link.dark_btn {padding: 9px 30px;}
    .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {right: 5px;}



    .banner_section .banner_slider .right_icon {right: -30px;}

    /* features section */
    .features_section .feature_detail .feature_box {width: 300px;}
    .features_section .feature_detail .left_data {padding-left: 15px;}
    .features_section .feature_detail .right_data {padding-right: 15px;}

    /* about app section */
    .about_app_section .about_text .app_statstic li {width: 210px; padding-left: 20px;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 30px;}

    /* modern ui section */
    .modern_ui_section .ui_images::before {width: 475px; height: 475px;}
    .modern_ui_section .ui_images .right_img img:nth-child(1) {margin-left: -60px;}
    .modern_ui_section .ui_images .right_img img:nth-child(2) {margin-left: -140px;}

    /* download app section */
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.8);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -120px;}
    .free_app_section .container .free_app_inner .free_img {margin-top: -180px;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}


    .youtube-video .modal-dialog  {max-width: 940px !important;}

    .app_solution_section .app_images ul li:nth-child(2) {width: 45%; left: 0;}
    .app_solution_section .app_images ul li:nth-child(3) {width: 50%; right: 0;}

    .experts_team_section .experts_box .text span {min-height: 50px; display: block; margin-bottom: 10px;}

    .review_list_section .review_box h3 {font-size: 16px;}
    .review_list_section .review_box p {font-size: 14px;}
    .review_list_section .review_box .reviewer .text span {font-size: 14px;}

    .contact_page_section .contact_inner {margin-top: -120px;}
    .contact_page_section .contact_inner .contact_form {width: 520px; padding: 50px 30px;}
    .contact_page_section .contact_inner .contact_info {width: 350px;}
    .contact_page_section .contact_inner .section_title h2 {font-size: 34px;}

    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

    .blog_detail_section .blog_inner_pannel {margin-top: -100px; padding: 30px;}

}

@media screen and (max-width:992px) {

    /* navigation section */
    .navbar-toggler-icon {display: flex; align-items: center; color: var(--body-text-purple); justify-content: flex-end;}
    header.fix_style {padding: 5px 0;}

     /* navigation section */
    .navbar-expand-lg .navbar-nav {align-items: flex-start;}
    .navbar-toggler {padding-right: 0; line-height: 1.7;}
    .navbar-expand-lg .navbar-nav {padding: 20px 15px; background-color: var(--bg-white); border-radius: 15px; }
    .navbar-expand-lg .navbar-nav .nav-link { color: var(--body-text-purple);}
    .navbar-expand-lg .navbar-nav .nav-link.dark_btn {margin-left: 0; margin-top: 15px;}
    .navbar-expand-lg .navbar-nav .has_dropdown {flex-direction: column; align-items: flex-start; width: 100%; box-shadow: none;}
    .navbar-expand-lg .navbar-nav .has_dropdown:hover {box-shadow: none;}
    .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {position: absolute; right: 0; padding: 0 10px; color: var(--body-text-purple)}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {position: relative; opacity: 1; pointer-events: all; top: auto; background-color: transparent; width: 100%; border-bottom: 1px solid #6b49f268; border-radius: 0; width: 100%; min-width: 100%; max-width: 100%; margin-left: 10px; display: none; box-shadow: none; margin-top: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {padding: 0; margin-top: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {padding: 5px 10px; display: block; padding-left: 0;}
    .navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {margin-top: 0;}

}

 /* @media screen and (max-width:767px) {

    .navbar {padding-top: 15px;}
    .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {text-align: left; line-height: 1.5; font-size: 14px;}
    .sub_menu {
  display: none;
}


.sub_menu.show {
  display: block;
}
.sub_menu {
  display: none;
}

.sub_menu.show {
  display: block;
}

.sub_menu {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.sub_menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sub_menu ul li {
  margin: 0;
  padding: 0;
}

.sub_menu ul li a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #333;
}

.sub_menu ul li a:hover {
  background-color: #eee;
} 

} */


@media screen and (max-width: 767px) {
  /* navbar section */
  .navbar {
    padding-top: 15px;
  }
  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
    text-align: left;
    line-height: 1.5;
    font-size: 14px;
  }

  .sub_menu {
    display: none;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  }

  .sub_menu.show {
    display: block;
  }

  .sub_menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sub_menu ul li {
    margin: 0;
    padding: 0;
  }

  .sub_menu ul li a {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    color: #333;
  }

  .sub_menu ul li a:hover {
    background-color: #eee;
  }
}


 `