import React from 'react'
import Header from 'component/header';
import HeaderRoutes from 'routes/headerRoutes';
import Footer from 'component/footer/index'
import { Main } from './style';
const MainHeader = () => {
  return (
    <Main>
    {/* Page-wrapper-Start  */}
  <div className="page_wrapper">
    <div>
    <Header/>
    </div>
   <div>
    <HeaderRoutes />
   </div>
    <div>
        <Footer />
    </div>
    </div>
    </Main>
  )
}

export default MainHeader;
