import styled from "styled-components";
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main=styled.div`


/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}

/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* -------------------Pricing-Section---------------------- */

/* pricing wraper  */
.pricing_section .toggle_block {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* pricing toggle button */
.pricing_section .toggle_block span {
  color: var(--dark-purple);
  font-weight: 600;
  display: block;
  margin: 0 5px;
}

.tog_btn.month_active {
  left: 35px !important;
}

.pricing_section .toggle_block span.deactive {
  color: var(--body-text-purple);
}

.pricing_section .toggle_block .offer {
  background-color: var(--bg-white);
  border-radius: 5px;
  padding: 2px 10px;
  font-weight: 400;
  font-size: 13px;
  color: var(--purple);
}

.pricing_section .toggle_block .tog_block {
  width: 70px;
  height: 35px;
  background-color: var(--bg-white);
  border-radius: 18px;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

.pricing_section .toggle_block .tog_block .tog_btn {
  height: 23px;
  width: 23px;
  border-radius: 25px;
  display: block;
  background-color: var(--purple);
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  transition: .4s all;
}

.pricing_section .toggle_block .month.active,
.pricing_section .toggle_block .years.active {
  color: var(--purple);
}

/* pricing pannel */
.pricing_section .pricing_pannel {
  margin-top: 50px;
  display: none;
}

.pricing_section .pricing_pannel.active {
  display: block;
}

.pricing_section .pricing_pannel .pricing_block {
  text-align: center;
  background-color: var(--bg-white);
  min-height: 700px;
  border-radius: 12px;
  padding-top: 60px;
  margin-bottom: 40px;
  box-shadow: 0px 4px 30px #EDE9FE;
}

.pricing_section .pricing_pannel .pricing_block.highlited_block {
  background-color: var(--purple);
}

.pricing_section .pricing_pannel .pricing_block.highlited_block p,
.pricing_section .pricing_pannel .pricing_block.highlited_block h3,
.pricing_section .pricing_pannel .pricing_block.highlited_block span,
.pricing_section .pricing_pannel .pricing_block.highlited_block .pkg_name span {
  color: var(--text-white);
}

.pricing_section .pricing_pannel .pricing_block .icon {
  margin-bottom: 35px;
}

/* pricing box image */
.pricing_section .pricing_pannel .pricing_block .icon img {
  width: 120px;
}

/* pricing box heading h3 */
.pricing_section .pricing_pannel .pricing_block .pkg_name h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.pricing_section .pricing_pannel .pricing_block .pkg_name span {
  color: var(--body-text-purple);
  font-size: 15px;
}

.pricing_section .pricing_pannel .pricing_block .price {
  font-size: 48px;
  color: var(--purple);
  margin: 25px 0;
  display: block;
  font-weight: 600;
}

/* pricing box list */
.pricing_section .pricing_pannel .pricing_block .benifits {
  margin-bottom: 40px;
}

.pricing_section .pricing_pannel .pricing_block .benifits li p {
  margin-bottom: 5px;
}

.pricing_section .contact_text {
  text-align: center;
  margin-bottom: 0;
}

.pricing_section .contact_text a {
  color: var(--purple);
  text-decoration: underline;
}

/* -------------FAQ-Section-Css-Start----------------- */

/* faq wraper */
.faq_section .faq_panel {
  margin-top: 40px;
}

/* faq box */
.faq_section .faq_panel .card {
  border: none;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px #EDE9FE;
  padding: 16px 0;
}

.faq_section .faq_panel .card:last-child {
  margin-bottom: 0;
}

.faq_section .faq_panel .card-header {
  background-color: transparent;
  border: none;
  padding-bottom: 0;
  padding-top: 0;
}

.faq_section .faq_panel .card-header .btn {
  padding: 0;
  color: var(--dark-purple);
  font-weight: 600;
  font-size: 20px;
}

.faq_section .faq_panel .card-header .btn.active {
  color: var(--purple);
}

.faq_panel .accordion button,
.faq_panel .accordion button:hover,
.faq_panel .accordion button:focus {
  text-decoration: none;
}

.faq_section .faq_panel .card-header .icon_faq {
  position: absolute;
  right: 20px;
  color: #839BC0;
}

/* faq heading h2 */
.faq_section .faq_panel .card-header h2 {
  line-height: 1;
}

/* faq paragraph */
.faq_section .faq_panel .card-body {
  padding-bottom: 0;
}

/* -----------Download_App_Section-Start------------------ */

/* download app wraper */
.free_app_section {
  padding-top: 70px;
  position: relative;
}

.free_app_section .container {
  max-width: 1370px;
}

.free_app_section .container .free_app_inner {
  background-color: var(--bg-purple);
  border-radius: 30px;
  padding: 20px 100px;
  padding-bottom: 50px;
  position: relative;
  z-index: 999999;
}

/* download app dark background */
.free_app_section .container .free_app_inner .dark_bg {
  overflow: hidden;
}

.free_app_section .container .free_app_inner .dark_bg span {
  z-index: 9999;
}

.free_app_section .container .free_app_inner .row {
  align-items: center;
}

.free_app_section .container .free_app_inner .free_text .section_title {
  text-align: left;
}

/* download app heading h2 */
.free_app_section .container .free_app_inner .free_text .section_title h2 {
  margin-bottom: 20px;
}

.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
  color: var(--text-white);
}

.free_app_section .container .free_app_inner .free_text .app_btn {
  display: flex;
  align-items: center;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--purple);
  position: relative;
  border-radius: 12px;
  transition: .4s all;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a:hover {
  -webkit-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
-moz-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
}

.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {
  margin-left: 25px;
}

.free_app_section .container .free_app_inner .free_img {
  display: flex;
  align-items: center;
  margin-top: -120px;
}

.free_app_section .container .free_app_inner .free_img img:last-child {
  margin-left: -65px;
}

.purple_backdrop {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(50, 35, 111, 0.95);
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0;
  transition: .4s all;
  pointer-events: none;
}

/* pricing switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: white;
}

input:focus + .slider {
 background-color: white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
   background-color: white;
}

.slider.round:before {
  border-radius: 50%;
   background-color: #6A49F2;
}
/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}


@media screen and (max-width:1200px) {

.row_am {padding: 50px 0;}

/* footer shape animation */
 .banner_shape1 { top: -30px; left: -2%;}
 .banner_shape2 { top: 100px; right: -2%; }
 .banner_shape3 { top: 400px; right: -4%; }

/* download app section */
.free_app_section .container .free_app_inner .free_img img {transform: scale(.8);}
.free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -120px;}
.free_app_section .container .free_app_inner .free_img {margin-top: -180px;}

/* newsletter section */
.newsletter_box .section_title {width: 60%;}
.newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
.newsletter_box form .form-group .form-control {width: 250px;}

.bred_crumb {min-height: 400px;}
.bred_crumb::after {background-size: 100%; height: 110px;} 
}

@media screen and (max-width:992px) {

.free_app_section .container .free_app_inner .free_text .section_title h2 , .section_title h2 {font-size: 30px;}

/* pricing section */
.pricing_section .pricing_pannel .pricing_block {padding-left: 15px; padding-right: 15px; min-height: 600px; padding-top: 30px;}
.pricing_section .pricing_pannel .pricing_block .price {font-size: 36px;}
.pricing_section .pricing_pannel .pricing_block .benifits li p {font-size: 14px;}

/* download app section */
.free_app_section .container .free_app_inner {padding: 20px 15px;}
.free_app_section .container .free_app_inner .free_img img {transform: scale(.7);}
.free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -180px;}
.free_app_section .container .free_app_inner .free_text .app_btn li a {padding: 10px 20px;}

/* newsletter section */
.newsletter_box {flex-direction: column; padding: 30px 20px;}
.newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
.newsletter_box form .form-group .form-control {width: 350px;}
.newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}

.text span {min-height: auto;}
 .white_btn {font-size: 14px;}
}

@media screen and (max-width:767px) {

    
.row_am {padding: 30px 0;}
.section_title p br {display: none;}
.free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}


/* pricing section */
.pricing_section .pricing_pannel .pricing_block {min-height: auto; padding: 30px 15px;}
.pricing_section .pricing_pannel .pricing_block .icon {margin-bottom: 15px;}
.pricing_section .contact_text {margin-top: -20px;}

/* faq section */
.faq_section .faq_panel .card-header h2 {text-align: left;}
.faq_section .faq_panel .card-header .btn {font-size: 16px; padding-right: 30px;}
.faq_section .faq_panel .card-body p {text-align: left;}

/* download section */
.free_app_section .container .free_app_inner {padding: 50px 15px; padding-bottom: 0; z-index: 99;}
.free_app_section .container .free_app_inner .free_img {margin-top: 0; justify-content: center;}
.free_app_section .container .free_app_inner .free_img img {max-width: 100%;}
.free_app_section .container .free_app_inner .free_text .section_title {text-align: center;}
.free_app_section .container .free_app_inner .free_text .app_btn {flex-direction: column;}
.free_app_section .container .free_app_inner .free_text .app_btn li a {width: 200px; text-align: center;}
.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {margin-left: 0; margin-top: 15px;}

/* newsletter section */
.newsletter_box form {flex-direction: column; align-items: center;}
.newsletter_box form .form-group {width: 100%;}
.newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
.newsletter_box .section_title p {font-size: 13px;}
.purple_backdrop {display: none;}

}


@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}


`