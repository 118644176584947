import styled from "styled-components";
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main=styled.div`


.row_am {
    padding: 50px 0;
}

/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}

/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
    width: 248px;
    background-color: var(--bg-white);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 15px 10px;
    padding-left: 35px;
    box-shadow: 0px 4px 10px #EDE9FE;
}

.about_app_section .about_text .app_statstic li .icon {
    margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
    margin-bottom: 0;
    line-height: 1;
    color: var(--dark-purple);
}

.about_app_section .about_text .app_statstic li p:first-child {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 3px;
}

/* ----About App Soluction Section--- */

.app_solution_section .row {
    align-items: center;
}

.app_solution_section .app_text .section_title {
    text-align: left;
    margin-bottom: 20px;
} 

.app_solution_section .app_text .section_title h2 {
    letter-spacing: -1px;
}

.app_solution_section .app_text p {
    padding-right: 40px;
}

.app_solution_section .app_images {
    position: relative;
}

.app_solution_section .app_images ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.app_solution_section .app_images ul li {
    position: relative;
}

.app_solution_section .app_images ul li a {
    position: relative;
}

.app_solution_section .app_images ul li a .play_icon {
    background-color: rgba(255, 255, 255, 0.1);
    width: 96px;
    height: 96px;
    border-radius: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 96px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);

}

.app_solution_section .app_images ul li img {
    max-width: 100%;
}

.app_solution_section .app_images li:first-child {
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}

.app_solution_section .app_images ul li:nth-child(2) {
    left: -5px;
    cursor: pointer;
}

.app_solution_section .app_images ul li:nth-child(3) {
    right: -5px;
}

.app_solution_section .app_images::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
    height: 570px;
    border-radius: 100%;
    background-color: var(--bg-white);
    z-index: -1;
}

/* ------Why Section CSS Start------ */

.why_we_section {
    display: flex;
    justify-content: center;
}

.why_we_section .why_inner {
    background-color: var(--bg-white);
    border-radius: 30px;
    width: 1370px;
    margin: 0 15px;
    box-shadow: 0 4px 30px #EDE9FE;
    padding: 85px 0;
}

.why_we_section .why_inner .section_title {
    margin-bottom: 50px;
}

.why_we_section .why_inner .why_box .icon {
    margin-bottom: 45px;
}

.why_we_section .why_inner .why_box .icon img {
    max-width: 100%;
}

.why_we_section .why_inner .why_box .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-purple);
    margin-bottom: 10px;
}

.why_we_section .why_inner .why_box .text p {
    margin-bottom: 0;
}
/* CTA section Shape animation */

.query_section .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.query_section .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.query_section .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.query_section .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}


@keyframes mymove {
  50% {transform: rotate(180deg);}
}
/* ---------Query-Section-Css-Start-------- */
.query_section {
    display: flex;
    justify-content: center;
}

.query_inner {
    width: 1370px;
    margin: 0 15px;
    /*background-image: url(../images/query_bg.png);*/
    background: var(--purple);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    min-height: 420px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.query_inner .section_title h2 , 
.query_inner .section_title p {
    color: #fff;
}

.query_inner .section_title {
    margin-bottom: 40px;
}

.query_inner .white_btn {
    font-size: 25px;
    font-weight: 700;
}

.query_inner .white_btn:hover {
    border-color: #fff;
}

.about_trust_section {
    margin-bottom: 40px;
}



/* About-Page-Sectino */

.about_page_sectino img {max-width: 100%;}

/* -----------experts_team_sectio---------- */

.experts_team_section .experts_box {
    position: relative;
    text-align: center;
    padding: 35px 30px;
    border-radius: 12px;
    background-color: transparent;
    transition: .4s all;
}

.experts_team_section .experts_box img {
    margin-bottom: 30px;
    max-width: 100%;
}

.experts_team_section .experts_box .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-purple);
    transition: .4s all;
}

.experts_team_section .experts_box .text span {
    color: var(--body-text-purple);
}

.experts_team_section .experts_box .social_media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.experts_team_section .experts_box .social_media a {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid #CCCCE2;
    border-radius: 50px;
    text-align: center;
    line-height: 31px;
    color: #898AAE;
    margin: 0 5px;
    transition: .4s all;
    font-size: 15px;
}

.experts_team_section .experts_box .social_media a:hover {
    border-color: var(--purple);
    background-color: var(--bg-purple);
    color: var(--text-white);
}

.experts_team_section .experts_box:hover {
    background-color: var(--bg-white);
    box-shadow: 0 4px 10px #EDE9FE;
}

.experts_team_section .experts_box:hover h3 {
    color: var(--purple);
}

/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}


/* ------Media-Query-Start--------- */

@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    /* about app section */
    .about_app_section .about_text .app_statstic li {width: 210px; padding-left: 20px;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 30px;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}



    .app_solution_section .app_images ul li:nth-child(2) {width: 45%; left: 0;}
    .app_solution_section .app_images ul li:nth-child(3) {width: 50%; right: 0;}

    .experts_team_section .experts_box .text span {min-height: 50px; display: block; margin-bottom: 10px;}


    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

}

@media screen and (max-width:992px) {

   

    /* about app section */
    .about_app_section .row {flex-direction: column-reverse;}
    .about_app_section .about_img {justify-content: flex-start; margin-top: 75px; margin-left: 0;}
    .about_app_section .about_img::before {left: 50%;}
    .about_app_section .about_img .screen_img {margin-left: -170px;}
    .about_app_section .about_text .app_statstic {justify-content: flex-start;}
    .about_app_section .about_text .app_statstic li {width: 35%; margin-right: 30px;}
    .about_app_section .about_img::before {left: 35%;}
    

    /* newsletter section */
    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}

  

    /* Bred Crumb */
    .bred_crumb {min-height: 350px;}
    .bred_crumb .bred_text h1 {font-size: 30px;}
    .bred_crumb::after {background-size: 100%; height: 90px; bottom: -5px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}

    .bred_crumb .banner_shape3 { top: 240px;   animation: mymove 3s infinite; }
    

    .app_solution_section .app_images {margin-top: 30px;}
    .app_solution_section .app_images ul li:nth-child(2) {text-align: right;}
    .app_solution_section .app_text p {padding-right: 0;}
    .app_solution_section .app_text .section_title {text-align: center;}
    .app_solution_section .app_images {margin-top: 70px;}
    .app_solution_section .app_images::before {width: 350px; height: 350px;}

    .why_we_section .why_inner {padding: 35px 0; padding-bottom: 0px;}
    .why_we_section .why_inner .why_box {margin-bottom: 50px; text-align: center;} 
    .why_we_section .why_inner .why_box .icon {margin-bottom: 30px;}
    

    .experts_team_section .experts_box .text span {min-height: auto;}
    .query_inner .white_btn {font-size: 14px;}


    

}

@media screen and (max-width:767px) {
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}



    /* trusted logo section */
    .trusted_section .company_logos {padding-top: 0;}
    .trusted_section .company_logos img {max-width: 70%;}

    /* about app section */
    .about_app_section .about_text , .about_app_section .about_text .section_title {text-align: center;}
    .about_app_section .about_text .app_statstic {margin-top: 0; justify-content: space-between;}
    .about_app_section .about_text .app_statstic li {width: 48%; margin-right: 0;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 18px;}
    .about_app_section .about_img {margin: 0; margin-top: 50px; justify-content: center;}
    .about_app_section .about_img::before {width: 350px; height: 350px;}



    /* faq section */
    .faq_section .faq_panel .card-header h2 {text-align: left;}
    .faq_section .faq_panel .card-header .btn {font-size: 16px; padding-right: 30px;}
    .faq_section .faq_panel .card-body p {text-align: left;}



    /* newsletter section */
    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}

    /* Bread-Crumb */
    .bred_crumb {min-height: 330px;}
    .bred_crumb .bred_text h1 {font-size: 24px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
    .bred_crumb .bred_text {padding: 0 15px; margin-top: 15px;}
    .bred_crumb::after {background-size: cover; height: 100px;} 


    .why_we_section .why_inner {padding-bottom: 10px;}

    .experts_team_section .experts_box {padding: 20px; margin-bottom: 15px;}


}

@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}


`