import styled from "styled-components";
import RightIcon from 'assets/right_icon.png'
import IconBg from 'assets/icon_bg.png'
import Testimonialbg from 'assets/testimonial_bg.png'
export const Main=styled.div`

/* ---------Hero-banner-Css-Start------------------ */
/* hero banner wraper */
.banner_section {
    padding-top: 180px;
    position: relative;
    background: #6a49f2;
}

.banner_section .container {
    position: relative;
}

/* wave backgound after banner */
.banner_section::after {
    /* content: "";
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
    margin-top: -50px; */
}

.banner_section .row {
    align-items: center;
}
/* hero banner text */
.banner_section .banner_text {
    margin-top: -50px;
}

/* hero banner heading h1 */
.banner_section .banner_text h1 {
    font-size: 55px;
    color: var(--text-white);
    letter-spacing: -1.5px;
    font-weight: 700;
}

.banner_section .banner_text h1 span {
    color: var(--text-white);
}

.banner_section .banner_text p {
    color: var(--text-white);
}

/* hero banner button */
.banner_section .app_btn {
    display: flex;
    align-items: center;
    margin: 30px 0 100px;
}

/* hero banner list */
.banner_section .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}

.banner_section .app_btn li:last-child {
    margin-left: 25px;
}

.banner_section .app_btn li a img {
    transition: .4s all;
}

.banner_section .app_btn li a .white_img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.banner_section .app_btn li a:hover {
    background-color: var(--purple);
    border: 2px solid var(--bg-white);
}

.banner_section .app_btn li a:hover .blue_img {
    opacity: 0;
}

.banner_section .app_btn li a:hover .white_img {
    opacity: 1;
}

/* hero banner users */
.banner_section .used_app {
    display: flex;
    align-items: center;
    margin-top: 35px;
}

.banner_section .used_app ul {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
    margin-left: -15px;
}

.banner_section .used_app p {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 0;
    color: var(--text-white);
}

/* hero banner images */
.banner_section .banner_image {
    display: flex;
    position: relative;
    width: 100%;
}

.banner_section .banner_image img{
    max-width: 100%;
}


.owl-carousel .owl-item img {
    max-width: 100%;
    width: auto;
}


/* hero banner shape animation */
.banner_section .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.banner_section .banner_shape1 {
    top: 200px;
    left: -15%;
    animation: mymove 10s infinite;
}

.banner_section .banner_shape2 {
    top: 100px;
    right: -10%;
    animation: mymove 5s infinite;
}

.banner_section .banner_shape3 {
    top: 400px;
    right: -20%;
    animation: mymove 3s infinite;
}


@keyframes mymove {
  50% {transform: rotate(180deg);}
}


/* ------------Trusted-Section-Css-Start----------- */

/* trusted logos wraper */
.trusted_section {
    margin-top: 40px;
}

.trusted_section .company_logos {
    padding-top: 20px;
}

.trusted_section .company_logos img {
    filter: grayscale(1);
    margin: 0 auto;
    transition: .4s all;
}

.trusted_section .company_logos img:hover {
    filter: grayscale(0);
}
.number-clr{
    color: var(--bg-purple);
}
.row_am {
    padding: 50px 0;
}


/* ----------Feature-Detail-Section-start------ */

/* features section wraper */
.features_section .feature_detail {
    background-color: var(--bg-white);
    border-radius: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    padding-top: 60px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 30px #EDE9FE;
}

/* features section image */
.features_section .feature_detail .feature_img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -75px;
}

.features_section .feature_detail .feature_img img {
    max-width: 100%;
}

/* features section box */

.features_section .feature_detail .feature_box {
    max-width: 410px;
}

.features_section .feature_detail .feature_box .data_block {
    margin-bottom: 50px;
}

.features_section .feature_detail .feature_box .data_block h4 {
    font-size: 20px;
    color: var(--dark-purple);
    font-weight: 600;
}

.features_section .feature_detail .left_data {
    text-align: right;
    padding-left: 130px;
}

.features_section .feature_detail .right_data {
    padding-right: 130px;
}

.features_section .feature_detail .left_data .data_block .icon {
    margin-right: -15px;
}

.features_section .feature_detail .right_data .data_block .icon {
    margin-left: -15px;
}

.features_section .container {
    max-width: 1370px;
}

/* about us section wraper */
.about_app_section .about_img {
    display: flex;
    align-items: center;
    position: relative;
}

/* about us section images*/
.about_app_section .about_img img {
    max-width: 100%;
}

.about_app_section .about_img::before {
    content: "";
    position: absolute;
    left: 38%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    background-color: var(--bg-white);
    border-radius: 100%;
    z-index: -1;
}

.about_app_section .about_img .screen_img {
    margin-left: -135px;
    margin-top: 110px;
}

.about_app_section .about_text .section_title {
    text-align: left;
}

.about_app_section .about_text .section_title h2 {
    margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
    width: 248px;
    background-color: var(--bg-white);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 15px 10px;
    padding-left: 35px;
    box-shadow: 0px 4px 10px #EDE9FE;
}

.about_app_section .about_text .app_statstic li .icon {
    margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
    margin-bottom: 0;
    line-height: 1;
    color: var(--dark-purple);
}

.about_app_section .about_text .app_statstic li p:first-child {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 3px;
}

/* -------------Modern-Ui-Section-Css-Start---------------- */
/* modern ui section wraper */
.modern_ui_section .row {
    align-items: center;
}

.modern_ui_section .design_block {
    margin-top: 45px;
}

/* modern ui text */
.modern_ui_section .section_title {
    text-align: left;
}

.modern_ui_section .ui_text {
    padding-right: 75px;
}

/* modern ui list */
.modern_ui_section .design_block li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 25px;
}

.modern_ui_section .design_block li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    background-image: url(${RightIcon});

    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.modern_ui_section .design_block li h4 {
    font-size: 20px;
    color: var(--dark-purple);
    font-weight: 600;
    margin-bottom: 8px;
}

.modern_ui_section .design_block li p {
    margin-bottom: 0;
}

/* modern ui images */
.modern_ui_section .ui_images {
    display: flex;
    position: relative;
}

.modern_ui_section .ui_images::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
    height: 570px;
    border-radius: 100%;
    background-color: var(--bg-white);
    z-index: -1;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
    margin-left: -140px;
    margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
    margin-left: -90px;
    margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
    position: relative;
    top: 15px;
    z-index: 99;
    margin-left: -15px;
}
/* -------------How_It_Works-Section-Css-Start------------------ */

/* how it works wraper */
.how_it_works .container {
    max-width: 1370px;
}

.how_it_works .how_it_inner {
    background-color: var(--bg-white);
    padding: 70px 0;
    padding-bottom: 250px;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #EDE9FE;
}

/* how it works list */
.how_it_works .step_block ul {
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px;
}

.how_it_works .step_block ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
}

.how_it_works .step_block ul li::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% + 100px);
    background-color: var(--light-bg);
}

.how_it_works .step_block ul li:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: var(--light-bg);
    border-radius: 15px;
}

.how_it_works .step_block ul li:first-child::before {
    top: 0;
}

.how_it_works .step_block ul li:last-child::before {
    height: 50%;
    top: 0;
}

.how_it_works .step_block ul li .step_text,
.how_it_works .step_block ul li .step_img {
    width: 360px;
    text-align: right;
}

/* how it works image */
.how_it_works .step_block ul li .step_img img {
    max-width: 100%;
}

/* how it works heading h4 */
.how_it_works .step_block ul li .step_text h4 {
    font-size: 20px;
    font-weight: 600;
}

.how_it_works .step_block ul li .step_text .app_icon {
    margin-bottom: 10px;
}

.how_it_works .step_block ul li .step_text .app_icon a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--light-bg);
    color: var(--text-white);
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    transition: .4s all;
}

.how_it_works .step_block ul li .step_text .app_icon a:hover {
    background-color: var(--purple);
}

.how_it_works .step_block ul li .step_text span {
    font-weight: 600;
}

.how_it_works .step_block ul li .step_text span a {
    color: var(--purple);
    text-decoration: underline;
}

.how_it_works .step_block ul li:nth-child(2) {
    flex-direction: row-reverse;
}

.how_it_works .step_block ul li:nth-child(2) .step_text,
.how_it_works .step_block ul li:nth-child(2) .step_img {
    text-align: left;
}

/* how it works numbers */
.how_it_works .step_block ul li .step_number {
    background-image: url(${IconBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* how it works numbers heading h3 */
.how_it_works .step_block ul li .step_number h3 {
    font-size: 30px;
    font-weight: 600;
}

/* how it works video  */
.how_it_works .yt_video {
    max-width: 1170px;
    margin: 0 auto;
    margin-top: -200px;
    position: relative;
    overflow: hidden;
}


.how_it_works .yt_video .thumbnil {
    position: relative;
}

.how_it_works .yt_video .thumbnil img {
    max-width: 100%;
}

.how_it_works .yt_video .thumbnil a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--text-white);
    font-weight: 600;
    z-index: 999;
    cursor: pointer;
}

.how_it_works .yt_video .thumbnil a span {
    display: block;
    font-weight: 700;
    font-size: 30px;
}

.how_it_works .yt_video .thumbnil a .play_btn {
    background-color: rgba(255, 255, 255, 0.1);
    width: 96px;
    height: 96px;
    border-radius: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 96px;
    position: relative;
    display: block;
    margin-bottom: 40px;
}

.how_it_works .yt_video .thumbnil a .play_btn img {
    width: 50px;
    position: relative;
    z-index: 999;
}

/* how it works video model   */
.modal {
    z-index: 999999;
}

.modal-backdrop.show {
    z-index: 99999;
    opacity: .7;
}

.youtube-video .modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    padding: 0 15px;
    height: 100%;
    max-width: 1240px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#video-container {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

iframe#youtubevideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.youtube-video .modal-footer {
    border: none;
    text-align: center;
    display: block;
    padding: 0;
}

.youtube-video .modal-content {
    background: none !important;
    border: none;
}

#close-video {
    color: #fff;
    font-size: 30px;
}

/* video banner shape animation */
.thumbnil .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.thumbnil .banner_shape1 {
    top: 100px;
    left: 10%;
    animation: mymove 10s infinite;
}

.thumbnil .banner_shape2 {
    bottom: 50px;
    left: 20%;
    animation: mymove 5s infinite;
}

.thumbnil .banner_shape3 {
    top: 40px;
    right: 8%;
    animation: mymove 3s infinite;
}


@keyframes mymove {
  50% {transform: rotate(180deg);}
}
/* ------------Testimonial-Slider-Css-Start------------- */
/* testimonials wraper  */
#testimonial_slider {
    max-width: 550px;
    margin: 0 auto;
}

.testimonial_section .testimonial_block {
    /* background-image: url(assets/testimonial_bg.png); */
    background-image: url(${Testimonialbg});
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 65px;
}

.testimonial_section .testimonial_block .testimonial_slide_box {
    text-align: center;
    width: 430px;
    padding: 10px;
    margin: 0 auto;
}

/* testimonials rating  */
.testimonial_section .testimonial_block .rating span {
    color: #FC9400;
    font-size: 18px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .review {
    margin-top: 10px;
    margin-bottom: 30px;
}

/* testimonials image  */
.testimonial_section .testimonial_block .testimonial_slide_box .testimonial_img img {
    margin: 0 auto;
}

/* testimonials heading h3 */
.testimonial_section .testimonial_block .testimonial_slide_box h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .designation {
    font-size: 15px;
}

/* testimonials total review */
.testimonial_section .total_review {
    text-align: center;
    margin-top: 60px;
}

.testimonial_section .total_review .rating {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* testimonials paragraph */
.testimonial_section .total_review .rating p {
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 5px;
    color: var(--dark-purple);
}

/* testimonials heading */
.testimonial_section .total_review h3 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--dark-purple);
}

.testimonial_section .total_review a {
    color: var(--purple);
    font-weight: 700;
}

.testimonial_section .testimonial_block .avtar_faces {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
}

.testimonial_section .testimonial_block .avtar_faces img {
    max-width: 100%;
}
/* -------------------Pricing-Section---------------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: white;
}

input:focus + .slider {
 background-color: white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
   background-color: white;
}

.slider.round:before {
  border-radius: 50%;
   background-color: #6A49F2;
}

/* pricing wraper  */
.pricing_section .toggle_block {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* pricing toggle button */
.pricing_section .toggle_block span {
    color: var(--dark-purple);
    font-weight: 600;
    display: block;
    margin: 0 5px;
}

.tog_btn.month_active {
    left: 35px !important;
}

.pricing_section .toggle_block span.deactive {
    color: var(--body-text-purple);
}

.pricing_section .toggle_block .offer {
    background-color: var(--bg-white);
    border-radius: 5px;
    padding: 2px 10px;
    font-weight: 400;
    font-size: 13px;
    color: var(--purple);
}

.pricing_section .toggle_block .tog_block {
    width: 70px;
    height: 35px;
    background-color: var(--bg-white);
    border-radius: 18px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
}

.pricing_section .toggle_block .tog_block .tog_btn {
    height: 23px;
    width: 23px;
    border-radius: 25px;
    display: block;
    background-color: var(--purple);
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: .4s all;
}

.pricing_section .toggle_block .month.active,
.pricing_section .toggle_block .years.active {
    color: var(--purple);
}

/* pricing pannel */
.pricing_section .pricing_pannel {
    margin-top: 50px;
    display: none;
}

.pricing_section .pricing_pannel.active {
    display: block;
}

.pricing_section .pricing_pannel .pricing_block {
    text-align: center;
    background-color: var(--bg-white);
    min-height: 700px;
    border-radius: 12px;
    padding-top: 60px;
    margin-bottom: 40px;
    box-shadow: 0px 4px 30px #EDE9FE;
}

.pricing_section .pricing_pannel .pricing_block.highlited_block {
    background-color: var(--purple);
}

.pricing_section .pricing_pannel .pricing_block.highlited_block p,
.pricing_section .pricing_pannel .pricing_block.highlited_block h3,
.pricing_section .pricing_pannel .pricing_block.highlited_block span,
.pricing_section .pricing_pannel .pricing_block.highlited_block .pkg_name span {
    color: var(--text-white);
}

.pricing_section .pricing_pannel .pricing_block .icon {
    margin-bottom: 35px;
}

/* pricing box image */
.pricing_section .pricing_pannel .pricing_block .icon img {
    width: 120px;
}

/* pricing box heading h3 */
.pricing_section .pricing_pannel .pricing_block .pkg_name h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.pricing_section .pricing_pannel .pricing_block .pkg_name span {
    color: var(--body-text-purple);
    font-size: 15px;
}

.pricing_section .pricing_pannel .pricing_block .price {
    font-size: 48px;
    color: var(--purple);
    margin: 25px 0;
    display: block;
    font-weight: 600;
}

/* pricing box list */
.pricing_section .pricing_pannel .pricing_block .benifits {
    margin-bottom: 40px;
}

.pricing_section .pricing_pannel .pricing_block .benifits li p {
    margin-bottom: 5px;
}

.pricing_section .contact_text {
    text-align: center;
    margin-bottom: 0;
}

.pricing_section .contact_text a {
    color: var(--purple);
    text-decoration: underline;
}

/* -------------FAQ-Section-Css-Start----------------- */

/* faq wraper */
.faq_section .faq_panel {
    margin-top: 40px;
}

/* faq box */
.faq_section .faq_panel .card {
    border: none;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #EDE9FE;
    padding: 16px 0;
}

.faq_section .faq_panel .card:last-child {
    margin-bottom: 0;
}

.faq_section .faq_panel .card-header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
}

.faq_section .faq_panel .card-header .btn {
    padding: 0;
    color: var(--dark-purple);
    font-weight: 600;
    font-size: 20px;
}

.faq_section .faq_panel .card-header .btn.active {
    color: var(--purple);
}

.faq_panel .accordion button,
.faq_panel .accordion button:hover,
.faq_panel .accordion button:focus {
    text-decoration: none;
}

.faq_section .faq_panel .card-header .icon_faq {
    position: absolute;
    right: 20px;
    color: #839BC0;
}

/* faq heading h2 */
.faq_section .faq_panel .card-header h2 {
    line-height: 1;
}

/* faq paragraph */
.faq_section .faq_panel .card-body {
    padding-bottom: 0;
}

/* -----------Interface_Section-Css-Start----------------- */

/* interface wraper */
.interface_section .screen_slider {
    margin-top: 35px;
    min-height: 720px;
}

/* interface images */
.interface_section .owl-item .screen_frame_img img {
    transform: scale(.9);
    border: 2px solid #000;
    border-radius: 20px;
    transition: 1s all;
    margin: 0 auto;
}

.interface_section .owl-item.center .screen_frame_img img {
    transform: scale(1);
    border: 3px solid #000;

}


/* -----------Download_App_Section-Start------------------ */

/* download app wraper */
.free_app_section {
    padding-top: 70px;
    position: relative;
}

.free_app_section .container {
    max-width: 1370px;
}
.payment-offer{
    color: white;
    
}

.free_app_section .container .free_app_inner {
    background-color: var(--bg-purple);
    border-radius: 30px;
    padding: 20px 100px;
    padding-bottom: 50px;
    position: relative;
    z-index: 999999;
}

/* download app dark background */
.free_app_section .container .free_app_inner .dark_bg {
    overflow: hidden;
}

.free_app_section .container .free_app_inner .dark_bg span {
    z-index: 9999;
}

.free_app_section .container .free_app_inner .row {
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .section_title {
    text-align: left;
}

/* download app heading h2 */
.free_app_section .container .free_app_inner .free_text .section_title h2 {
    margin-bottom: 20px;
}

.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
    color: var(--text-white);
}

.free_app_section .container .free_app_inner .free_text .app_btn {
    display: flex;
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
-moz-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
}

.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {
    margin-left: 25px;
}

.free_app_section .container .free_app_inner .free_img {
    display: flex;
    align-items: center;
    margin-top: -120px;
}

.free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -65px;
}

.purple_backdrop {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(50, 35, 111, 0.95);
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0;
    transition: .4s all;
    pointer-events: none;
}

/* ---------------Latest_Story-Css-Start------------- */

/* latest story wraper */
.latest_story {
    margin-bottom: 80px;
}

/* latest story box */
.latest_story .story_box {
    background-color: var(--bg-white);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 30px #EDE9FE; 
}

/* latest story image */
.latest_story .story_box .story_img {
    position: relative;
}

.latest_story .story_box .story_img img {
    max-width: 100%;
    border-radius: 12px;

}

.latest_story .story_box .story_img span {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 14px;
    color: var(--text-white);
}

/* latest story pargraph */
.latest_story .story_box .story_text {
    padding: 20px 30px;
}

/* latest story heading h3 */
.latest_story .story_box .story_text h3 {
    color: var(--dark-purple);
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

/* latest story link text */
.latest_story .story_box .story_text a {
    color: var(--purple);
    margin-top: 25px;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.latest_story .story_box .story_text a:hover {
    text-decoration:underline;
}



/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}
.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
  height: 500px;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}

/* ------Media-Query-Start--------- */
@media screen and (max-width:1300px) {
    
    /* features section */
    .features_section .feature_detail .feature_box {width: 350px;}
    .features_section .feature_detail .left_data {padding-left: 75px;}
    .features_section .feature_detail .right_data {padding-right: 75px;}

    /* hero banner shape animation */
    .banner_section .banner_shape1 { top: 0px; left: -3%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }


}


@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    /* hero banner shape animation */
    .banner_section .banner_shape1 { top: -30px; left: -2%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }

    /* footer shape animation */
     .banner_shape1 { top: -30px; left: -2%;}
     .banner_shape2 { top: 100px; right: -2%; }
     .banner_shape3 { top: 400px; right: -4%; }



    .banner_section .right_icon {right: -30px;}

    /* features section */
    .features_section .feature_detail .feature_box {width: 300px;}
    .features_section .feature_detail .left_data {padding-left: 15px;}
    .features_section .feature_detail .right_data {padding-right: 15px;}

    /* about app section */
    .about_app_section .about_text .app_statstic li {width: 210px; padding-left: 20px;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 30px;}

    /* modern ui section */
    .modern_ui_section .ui_images::before {width: 475px; height: 475px;}
    .modern_ui_section .ui_images .right_img img:nth-child(1) {margin-left: -60px;}
    .modern_ui_section .ui_images .right_img img:nth-child(2) {margin-left: -140px;}

    /* download app section */
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.8);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -120px;}
    .free_app_section .container .free_app_inner .free_img {margin-top: -180px;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}


    .youtube-video .modal-dialog  {max-width: 940px !important;}

    .app_solution_section .app_images ul li:nth-child(2) {width: 45%; left: 0;}
    .app_solution_section .app_images ul li:nth-child(3) {width: 50%; right: 0;}

    .experts_team_section .experts_box .text span {min-height: 50px; display: block; margin-bottom: 10px;}

    .review_list_section .review_box h3 {font-size: 16px;}
    .review_list_section .review_box p {font-size: 14px;}
    .review_list_section .review_box .reviewer .text span {font-size: 14px;}

    .contact_page_section .contact_inner {margin-top: -120px;}
    .contact_page_section .contact_inner .contact_form {width: 520px; padding: 50px 30px;}
    .contact_page_section .contact_inner .contact_info {width: 350px;}
    .contact_page_section .contact_inner .section_title h2 {font-size: 34px;}

    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

    .blog_detail_section .blog_inner_pannel {margin-top: -100px; padding: 30px;}

}

@media screen and (max-width:992px) {

    .free_app_section .container .free_app_inner .free_text .section_title h2 , .section_title h2 {font-size: 30px;}

    .banner_section {text-align: center; padding-top: 200px;}
    .banner_section .app_btn , .banner_section .used_app {justify-content: center;}

    /* banner section */
    .banner_section .banner_text h1 {font-size: 40px;}
    .banner_section .banner_slider {margin-top: 50px;}
    .banner_section .banner_slider .left_icon {left: 0;}
    .banner_section .banner_slider .right_icon {right: 0;}

    .banner_section .banner_image {max-width: 100%; margin-top: 75px; text-align: center; display: inherit;}

    /* features section */
    .features_section .feature_detail .feature_img {width: 275px;}
    .features_section .feature_detail .feature_box .data_block {margin-bottom: 30px;}
    .features_section .feature_detail .feature_box {width: 200px;}

    /* about app section */
    .about_app_section .row {flex-direction: column-reverse;}
    .about_app_section .about_img {justify-content: flex-start; margin-top: 75px; margin-left: 0;}
    .about_app_section .about_img::before {left: 50%;}
    .about_app_section .about_img .screen_img {margin-left: -170px;}
    .about_app_section .about_text .app_statstic {justify-content: flex-start;}
    .about_app_section .about_text .app_statstic li {width: 35%; margin-right: 30px;}
    .about_app_section .about_img::before {left: 35%;}
    
    /* modern ui section */
    .modern_ui_section .ui_images {margin-top: 30px;}
    .modern_ui_section .ui_text {padding-right: 0;}
    .modern_ui_section .ui_images .right_img {display: flex; flex-direction: column; align-items: center;}

    /* how it work section */
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img {width: 280px;}
    .how_it_works .yt_video .thumbnil a {top: 57%;}
    .how_it_works .yt_video .thumbnil a span {font-size: 20px;}
    
    /* testimonial section */
    #testimonial_slider {max-width: 500px;}

    /* pricing section */
    .pricing_section .pricing_pannel .pricing_block {padding-left: 15px; padding-right: 15px; min-height: 600px; padding-top: 30px;}
    .pricing_section .pricing_pannel .pricing_block .price {font-size: 36px;}
    .pricing_section .pricing_pannel .pricing_block .benifits li p {font-size: 14px;}

    /* interface section */
    .interface_section .screen_slider {min-height: 550px;}

    /* download app section */
    .free_app_section .container .free_app_inner {padding: 20px 15px;}
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.7);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -180px;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a {padding: 10px 20px;}

    /* latest story section */
    .latest_story {margin-bottom: 40px;}
    .latest_story .story_box .story_text {padding: 15px;}

    /* newsletter section */
    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}

    .text span {min-height: auto;}
     .white_btn {font-size: 14px;}

}

@media screen and (max-width:767px) {

    
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}
    .free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}


    /* banner section */
    .banner_section {margin-top: 0px; padding-top: 175px;}
    .banner_section .banner_text h1 {font-size: 30px;}
    .banner_section .app_btn , .banner_section .used_app {justify-content: center;}
    .banner_section .app_btn li:last-child {margin-left: 0;}
    .banner_section .app_btn li {margin: 0 15px;}
    .banner_section .app_btn li a {padding: 10px 20px;}
    .banner_section .app_btn li a img {height: 25px;}
    .banner_section .banner_slider .left_icon {left: -40px;}
    .banner_section .banner_slider .right_icon {right: -40px; z-index: 1000;}
    .banner_section .banner_slider::before {width: 350px; height: 350px;}


    /* hero banner shape animation */
    .banner_section .banner_shape1, .banner_shape2, .banner_shape3 { display: none;}

    /* footer shape animation */
    .top_footer .banner_shape1, .banner_shape2, .banner_shape3 { display: none;}


    /* trusted logo section */
    .trusted_section .company_logos {padding-top: 0;}
    .trusted_section .company_logos img {max-width: 70%;}

    /* features section */
    .features_section .feature_detail {flex-direction: column-reverse; padding-top: 30px; margin-top: 0;}
    .features_section .feature_detail .feature_img {position: relative; top: auto; left: auto; transform: none; width: 75%; margin: 0 auto;}
    .features_section .feature_detail .feature_box {width: 100%; padding: 0 15px; text-align: center;}
    .features_section .feature_detail .left_data .data_block .icon {margin-right: 0;}
    .features_section .feature_detail .right_data .data_block .icon {margin-left: 0;}
    .features_section .feature_detail .feature_box .data_block {margin-bottom: 0; margin-top: 30px;}

    /* about app section */
    .about_app_section .about_text , .about_app_section .about_text .section_title {text-align: center;}
    .about_app_section .about_text .app_statstic {margin-top: 0; justify-content: space-between;}
    .about_app_section .about_text .app_statstic li {width: 48%; margin-right: 0;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 18px;}
    .about_app_section .about_img {margin: 0; margin-top: 50px; justify-content: center;}
    .about_app_section .about_img::before {width: 350px; height: 350px;}

    /* modern ui section */
    .modern_ui_section .ui_images {margin-top: 10px;}
    .modern_ui_section .section_title {text-align: center;}
    .modern_ui_section .design_block li {padding-left: 0; margin-bottom: 30px;}
    .modern_ui_section .design_block li::before {position: relative; left: auto; top: auto; display: inline-block;}

    .modern_ui_section .ui_images img {max-width: 100%;}
    .modern_ui_section .ui_images::before {width: 350px; height: 350px;}
    .modern_ui_section .ui_images .left_img {width: 70%;}
    .modern_ui_section .ui_images .right_img {justify-content: center;}
    .modern_ui_section .ui_images .right_img img:nth-child(1) {top: 0; width: 100px;}
    .modern_ui_section .ui_images .right_img img:nth-child(3) {margin-top: 0;}

    /* how it work section */
    .how_it_works .how_it_inner {padding: 50px 15px;}
    .how_it_works .step_block ul {padding-top: 30px;}
    .how_it_works .step_block ul li , .how_it_works .step_block ul li:nth-child(2) {flex-direction: column; padding-left: 30px;}
    .how_it_works .step_block ul li .step_text h4 {font-size: 18px;}
    .how_it_works .step_block ul li::before {left: 0; transform: none; height: calc(100% + 10px); top: 40px;}
    .how_it_works .step_block ul li:first-child::before {height: calc(100% + 50px);}
    .how_it_works .step_block ul li:first-child::after {left: -5px; transform: none; display: none;}
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img ,
    .how_it_works .step_block ul li:nth-child(2) .step_text, .how_it_works .step_block ul li:nth-child(2) .step_img {text-align: center;}
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img {width: 100%;}
    .how_it_works .step_block ul li .step_number {position: absolute; top: -5px; left: -23px; width: 50px; height: 50px;}
    .how_it_works .step_block ul li:last-child::before {opacity: 0;}
    .how_it_works .step_block ul li .step_number h3 {font-size: 15px; margin-bottom: 0; margin-top: -2px;}
    .how_it_works .yt_video {margin-top: -50px;}
    .how_it_works .yt_video .thumbnil {height: 300px; border-radius: 15px;}
    .how_it_works .yt_video .thumbnil img {height: 100%; object-fit: cover; object-position: center; border-radius: 15px;}
    .how_it_works .yt_video .thumbnil a {width: 100%;}
    .how_it_works .yt_video .thumbnil a .play_btn::after {width: 140px; height: 140px;}
    .how_it_works .yt_video .thumbnil a .play_btn::before {width: 120px; height: 120px;}
    .how_it_works .yt_video .thumbnil a .play_btn img {height: auto;}

    /* video banner shape animation */
    .thumbnil .banner_shape1, .banner_shape2, .banner_shape3 { display: none;}

    /* testimonial section */
    .testimonial_section .testimonial_block {margin: 0;}
    .testimonial_section .testimonial_block .avtar_faces {display: none;}
    .testimonial_section .testimonial_block .testimonial_slide_box {width: 100%;}

    /* pricing section */
    .pricing_section .pricing_pannel .pricing_block {min-height: auto; padding: 30px 15px;}
    .pricing_section .pricing_pannel .pricing_block .icon {margin-bottom: 15px;}
    .pricing_section .contact_text {margin-top: -20px;}

    /* faq section */
    .faq_section .faq_panel .card-header h2 {text-align: left;}
    .faq_section .faq_panel .card-header .btn {font-size: 16px; padding-right: 30px;}
    .faq_section .faq_panel .card-body p {text-align: left;}

    /* interface section */
    .interface_section .screen_slider { /*min-height: 720px;*/ }
    .interface_section .owl-item .screen_frame_img img {transform: scale(1);}

    /* download section */
    .free_app_section .container .free_app_inner {padding: 50px 15px; padding-bottom: 0; z-index: 99;}
    .free_app_section .container .free_app_inner .free_img {margin-top: 0; justify-content: center;}
    .free_app_section .container .free_app_inner .free_img img {max-width: 100%;}
    .free_app_section .container .free_app_inner .free_text .section_title {text-align: center;}
    .free_app_section .container .free_app_inner .free_text .app_btn {flex-direction: column;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a {width: 200px; text-align: center;}
    .free_app_section .container .free_app_inner .free_text .app_btn li:last-child {margin-left: 0; margin-top: 15px;}

    /* latest story section */
    .latest_story .story_box {margin-bottom: 30px;}

    /* newsletter section */
    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}

    /* latest story section */
    .latest_story .story_box .story_img img {width: 100%;}    



    .purple_backdrop {display: none;}



}

@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}

`