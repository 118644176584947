import styled from 'styled-components'
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main=styled.div`

.row_am {
    padding: 50px 0;
}

/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}

/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* Blog List Css Start */
.blog_list_story {
  margin-bottom: 50px;
}

.blog_list_main {
  position: relative;
}

.blog_list_main > .container > .row {
  align-items: center;
}

.blog_list_main .blog_img {
  position: relative;
}

.blog_list_main .blog_img img {
  max-width: 100%;
  border-radius: 12px;
}

.blog_list_main .blog_img span {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 14px;
  color: var(--text-white);
}

.blog_list_main .blog_text .section_title {
  text-align: left;
}

.blog_list_main .blog_text .choice_badge {
  font-size: 12px;
  background-color: #F8D979;
  display: inline-block;
  padding: 1px 10px;
  border-radius: 4px;
  color: var(--dark-purple);
  font-weight: 700;
  margin-bottom: 10px;
}

.blog_list_main .blog_text a {
  color: var(--purple);
  margin-top: 25px;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.blog_list_main .blog_text a:hover {
  text-decoration: underline;
}

.blog_list_story.row_am {
  padding-top: 30px;
}

.blog_list_story .story_box {
  margin-bottom: 40px;
  text-align: left;
}

/* Pagination Css Start */
.pagination_block ul {display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination_block ul li:not(:first-child , :last-child) a {margin: 0 5px;
  transition: .4s all;
  display: block;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50px;
  text-align: center;
  line-height: 35px;
}

.pagination_block ul li:first-child {
  margin-right: 20px;
}

.pagination_block ul li:last-child {
  margin-left: 20px;
}

.pagination_block ul li:not(:first-child , :last-child) a:hover , .pagination_block ul li:not(:first-child , :last-child) a.active {
  background-color: var(--bg-purple);
  color: var(--text-white);
}

.pagination_block ul li:first-child a:hover {
  color: var(--purple);
}

.pagination_block ul li:last-child  a:hover {
  color: var(--purple);
}


/* ---------------Latest_Story-Css-Start------------- */

/* latest story wraper */
.latest_story {
    margin-bottom: 80px;
}

/* latest story box */
.latest_story .story_box {
    background-color: var(--bg-white);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 30px #EDE9FE; 
}

/* latest story image */
.latest_story .story_box .story_img {
    position: relative;
}

.latest_story .story_box .story_img img {
    max-width: 100%;
    border-radius: 12px;

}

.latest_story .story_box .story_img span {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 14px;
    color: var(--text-white);
}

/* latest story pargraph */
.latest_story .story_box .story_text {
    padding: 20px 30px;
}

/* latest story heading h3 */
.latest_story .story_box .story_text h3 {
    color: var(--dark-purple);
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

/* latest story link text */
.latest_story .story_box .story_text a {
    color: var(--purple);
    margin-top: 25px;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.latest_story .story_box .story_text a:hover {
    text-decoration:underline;
}

/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}

/* ------Media-Query-Start--------- */

@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}

    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

}

@media screen and (max-width:992px) {

    /* latest story section */
    .latest_story {margin-bottom: 40px;}
    .latest_story .story_box .story_text {padding: 15px;}

    /* newsletter section */
    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}

    /* Bred Crumb */
    .bred_crumb {min-height: 350px;}
    .bred_crumb .bred_text h1 {font-size: 30px;}
    .bred_crumb::after {background-size: 100%; height: 90px; bottom: -5px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}

    .bred_crumb .banner_shape3 { top: 240px;   animation: mymove 3s infinite; }
        
    .blog_list_main .blog_text {margin-top: 30px;}
    .blog_detail_section .blog_inner_pannel {margin-top: -100px; padding: 30px;}
    .blog_detail_section .blog_inner_pannel .info ul {padding-left: 30px;}
    .comment_section ul li .comment { width: calc(100% - 280px); margin-left: 20px;}
    .blog_detail_section .blog_inner_pannel .quote_block {padding: 55px 30px 35px 30px;}
    

}

@media screen and (max-width:767px) {

    Main {font-size: 14px; text-align: center;}
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}

    /* latest story section */
    .latest_story .story_box {margin-bottom: 30px;}

    /* newsletter section */
    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}

    /* latest story section */
    .latest_story .story_box .story_img img {width: 100%;}    

    .purple_backdrop {display: none;}


    /* Bread-Crumb */
    .bred_crumb {min-height: 330px;}
    .bred_crumb .bred_text h1 {font-size: 24px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
    .bred_crumb .bred_text {padding: 0 15px; margin-top: 15px;}
    .bred_crumb::after {background-size: cover; height: 100px;} 



    .blog_list_main .blog_text .section_title , .blog_list_story .story_box {text-align: center;}
    .pagination_block ul li:not(:first-child , :last-child) a {width: 24px; height: 24px; line-height: 24px; margin: 0 3px; font-size: 12px;}

    .blog_detail_bredcrumb.bred_crumb .bred_text {margin-top: -10px;}
    .blog_detail_section .blog_inner_pannel {margin-top: -70px; padding: 25px 20px;}
    .blog_detail_section .blog_inner_pannel .section_title {margin-bottom: 20px;}
    .blog_detail_section .blog_inner_pannel .section_title h2 {font-size: 22px;}
    .blog_detail_section .blog_inner_pannel .info h3 {font-size: 18px;}
    .blog_detail_section .blog_inner_pannel .info ul {text-align: left; padding-left: 0;}
    .blog_detail_section .blog_inner_pannel .two_img {margin-bottom: 0;}
    .blog_detail_section .blog_inner_pannel .two_img img {margin: 10px 0;}
    .blog_detail_section .blog_inner_pannel .quote_block .q_icon {left: 50%; transform: translateX(-50%);}
    .blog_detail_section .blog_inner_pannel .quote_block {padding: 55px 20px 35px 20px;}
    .blog_detail_section .blog_inner_pannel .quote_block h2 {font-size: 18px; line-height: 1.4;}
    .blog_detail_section .blog_inner_pannel .blog_authore {flex-direction: column;}
    .blog_detail_section .blog_inner_pannel .blog_authore .authore_info {margin-bottom: 30px;}
    .blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {font-size: 18px;}

}

@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}


`