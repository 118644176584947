import styled from "styled-components";
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main=styled.div`



/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}
/* Review List Section Css Start */

.review_list_section .review_box {
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px #EDE9FE;
    margin: 20px 0;
}

.review_list_section .review_box .rating ul {
    display: flex;
}

.review_list_section .review_box .rating ul li span {
    color: #FC9400;
    font-size: 18px;
}

.review_list_section .review_box h3 {
    font-size: 20px;
    color: var(--purple);
    margin: 10px 0;
}

.review_list_section .review_box .reviewer {
    display: flex;
    align-items: center;
    margin-top: 35px;
}

.review_list_section .review_box .reviewer .avtar {
    width: 80px;
}

.review_list_section .review_box .reviewer .text {
    margin-left: 20px;
    width: calc(100% - 100px);
}

.review_list_section .review_box .reviewer .text h3 {
    font-weight: 600;
    color: var(--dark-purple);
    margin-bottom: 0;
}

/* review-freeapp */

.review_freeapp {
    margin: 60px 0;
}

.review_freeapp .free_app_inner {
    background: url('assets/free_review_bg.png');
    background-size: cover;
    background-position: 90%;
}


/* -----------Download_App_Section-Start------------------ */

/* download app wraper */
.free_app_section {
    padding-top: 70px;
    position: relative;
}

.free_app_section .container {
    max-width: 1370px;
}

.free_app_section .container .free_app_inner {
    background-color: var(--bg-purple);
    border-radius: 30px;
    padding: 20px 100px;
    padding-bottom: 50px;
    position: relative;
    z-index: 999999;
}

/* download app dark background */
.free_app_section .container .free_app_inner .dark_bg {
    overflow: hidden;
}

.free_app_section .container .free_app_inner .dark_bg span {
    z-index: 9999;
}

.free_app_section .container .free_app_inner .row {
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .section_title {
    text-align: left;
}

/* download app heading h2 */
.free_app_section .container .free_app_inner .free_text .section_title h2 {
    margin-bottom: 20px;
}

.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
    color: var(--text-white);
}

.free_app_section .container .free_app_inner .free_text .app_btn {
    display: flex;
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
-moz-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
}

.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {
    margin-left: 25px;
}

.free_app_section .container .free_app_inner .free_img {
    display: flex;
    align-items: center;
    margin-top: -120px;
}

.free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -65px;
}
/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}




`