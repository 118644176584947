import React from 'react'
import Shape1 from 'assets/banner-shape1.png'
import Shape2 from 'assets/banner-shape2.png'
import Shape3 from 'assets/banner-shape3.png'
import Gotop from 'assets/go_top.png'
//import CompanyLogo from 'assets/common/horumarkaalweb logo.png'
// import AppstoreBlue from 'assets/appstore_blue.png'
// import Googleplayblue from 'assets/googleplay_blue.png'
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import CompanyLogo from '../../assets/common/logosApp.png'
import { Main } from './style'
const Footer = () => {
  return (
    <>
      <Main>
      <footer>
    <div className="top_footer" id="contact">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <span className="banner_shape1">
            {" "}
            <img src={Shape1} alt="image" />{" "}
          </span>
          <span className="banner_shape2">
            {" "}
            <img src={Shape2} alt="image" />{" "}
          </span>
          <span className="banner_shape3">
            {" "}
            <img src={Shape3} alt="image" />{" "}
          </span>
          {/* footer link 1 */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="abt_side">
              <div className="logo">
                {" "}
                <img src={CompanyLogo} alt="image" />
              </div>
              <ul>
                <li>
                  <a href="#">Info@horumarkaal.app</a>
                </li>
                <li>
                <p style={{cursor:"pointer"}}>Customercare@hiigsikaal.com</p>
              </li>
                <li>
                  <p style={{cursor:"pointer"}}>06190190124</p>
                </li>

              </ul>
              <ul className="social_media">
                <li>
                  <a href="#">
                    <FacebookIcon className="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <XIcon className="icofont-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <InstagramIcon className="icofont-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <PinterestIcon className="icofont-pinterest" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* footer link 2 */}
          <div className="col-lg-3 col-md-6 col-12">
            <div className="links">
              <h3>Useful Links</h3>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
          {/* footer link 3 */}
          <div className="col-lg-3 col-md-6 col-12">
            <div className="links">
              <h3>Help &amp; Suport</h3>
              <ul>
                <li>
                  <a href="#">FAQs</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
                <li>
                  <a href="#">How it works</a>
                </li>
                <li>
                  <a href="#">Terms &amp; conditions</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
          </div>
          {/* footer link 4 */}
          {/* <div className="col-lg-2 col-md-6 col-12">
            <div className="try_out">
              <h3>Let’s Try Out</h3>
              <ul className="app_btn">
                <li>
                  <a href="#">
                    <img src={AppstoreBlue} alt="image" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={Googleplayblue} alt="image" />
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </div>
    {/* last footer */}
    
    {/* go top button */}
    <div className="go_top">
      <span>
        <img src={Gotop} alt="Gotop" />
      </span>
    </div>
  </footer>
      </Main>
    </>
  )
}

export default Footer;
