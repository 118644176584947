import styled from 'styled-components'
import BreadCrumbBg from 'assets/bread_crumb_bg.png';
import BreadCrumbOverlay from 'assets/inner_page_banner_overlay.svg';
export const Main =styled.div`

.row_am {
    padding: 50px 0;
}


/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}

/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(${BreadCrumbBg});
    /*background: var(--purple);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0px;
}

.bred_crumb::after {
    content: "";
    background-image: url(${BreadCrumbOverlay});
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-purple);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-purple);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-purple);
}
/* purple button */
.puprple_btn {
    background-color: var(--purple);
    color: var(--text-white);
    border-radius: 50px;
    padding: 10px 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
}

.puprple_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.puprple_btn:hover::before {
    width: 100%;
}

.puprple_btn:hover {
    color: var(--purple);
}

/* Contact Page Css Start */

.contact_page_section .contact_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: -150px;
}

.contact_page_section .contact_inner .section_title h2 {
    font-weight: 600;
}

.contact_page_section .contact_inner .contact_form {
    width: 570px;
    padding: 50px 60px;
    border-radius: 22px;
    background-color: #fff;
    box-shadow: 0 4px 10px #EDE9FE;
}

.contact_page_section .contact_inner .contact_form h2 {
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_form form {
    margin-top: 30px;
}

.contact_page_section .contact_inner .contact_form form .form-group {
    margin-bottom: 20px;
}

.contact_page_section .contact_inner .contact_form form .form-group .form-control {
    height: 60px;
    padding: 5px 20px;
    color: var(--body-text-purple);
    border: 2px solid #E1DBF4;
    border-radius: 12px;
    font-weight: 500;
}

.contact_page_section .contact_inner .contact_form form .form-group .form-control:focus {
    box-shadow: none;
    border-color: var(--purple);
    color: var(--purple);
}

.contact_page_section .contact_inner .contact_form form .form-group textarea.form-control {
    height: 140px;
    padding-top: 15px;
}

.contact_page_section .contact_inner .contact_form form .term_check {
    display: flex;
    align-items: center;
}

.contact_page_section .contact_inner .contact_form form .term_check input {
    width: 17px;
    height: 17px;
    accent-color: var(--purple);
}

.contact_page_section .contact_inner .contact_form form .term_check label {
    font-size: 13px;
    margin-bottom: 0;
    margin-left: 7px;
}

.contact_page_section .contact_inner .contact_form form .form-group button {
    width: 240px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info {
    width: 480px;
}

.contact_page_section .contact_inner .contact_info .section_title {
    text-align: left;
    margin-top: 15px;
}

.contact_page_section .contact_inner .contact_info .section_title p a {
    color: var(--purple);
    text-decoration: underline;
}

.contact_page_section .contact_inner .contact_info .btn {
    width: 180px;
    margin: 10px 0 50px 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li:last-child {
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .img {
    width: 65px;
    margin-right: 20px;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text {
    width: calc(100% - 85px);
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text span {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-purple);
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text p , 
.contact_page_section .contact_inner .contact_info .contact_info_list li .text a {margin: 0;}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text a:hover {
    color: var(--dark-purple);
}

.map_section {
    margin: 50px 0;
}
/* animation here */
@keyframes mymove {
  50% {transform: rotate(180deg);}
}


/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-purple);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--purple);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}



/* ------Media-Query-Start--------- */

@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    /* newsletter section */
    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h2 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}

    /* contact */
    .contact_page_section .contact_inner {margin-top: -120px;}
    .contact_page_section .contact_inner .contact_form {width: 520px; padding: 50px 30px;}
    .contact_page_section .contact_inner .contact_info {width: 350px;}
    .contact_page_section .contact_inner .section_title h2 {font-size: 34px;}

    .bred_crumb {min-height: 400px;}
    .bred_crumb::after {background-size: 100%; height: 110px;} 

}

@media screen and (max-width:992px) {




    /* newsletter section */
    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h2, .newsletter_box .section_title p {text-align: center;}


    /* Bred Crumb */
    .bred_crumb {min-height: 350px;}
    .bred_crumb .bred_text h1 {font-size: 30px;}
    .bred_crumb::after {background-size: 100%; height: 90px; bottom: -5px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}

    .bred_crumb .banner_shape3 { top: 240px;   animation: mymove 3s infinite; }
    
 

    .contact_page_section .contact_inner {flex-direction: column; margin-top: 60px;}
    .contact_page_section .contact_inner .contact_form {width: 100%;}
    .contact_page_section .contact_inner .contact_info {width: 100%; margin-top: 50px;}

    

}

@media screen and (max-width:767px) {

    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}
    .free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}


    /* newsletter section */
    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}


    /* Bread-Crumb */
    .bred_crumb {min-height: 330px;}
    .bred_crumb .bred_text h1 {font-size: 24px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
    .bred_crumb .bred_text {padding: 0 15px; margin-top: 15px;}
    .bred_crumb::after {background-size: cover; height: 100px;} 

    .contact_page_section .contact_inner {margin-top: 40px;}
    .contact_page_section .contact_inner .contact_form {padding: 30px 20px;}
    .contact_page_section .contact_inner .section_title h2 {font-size: 24px;}
    .contact_page_section .contact_inner .contact_form form .form-group .form-control {height: 50px; font-size: 14px;}
    .contact_page_section .contact_inner .contact_form form .term_check label {text-align: left; line-height: 1.3;}
    .contact_page_section .contact_inner .contact_form form .term_check {align-items: flex-start;}
    .contact_page_section .contact_inner .contact_info .section_title {text-align: center;}
    .contact_page_section .contact_inner .contact_info .btn {margin-bottom: 30px;}
    .contact_page_section .contact_inner .contact_info .contact_info_list li {flex-direction: column;}
    .contact_page_section .contact_inner .contact_info .contact_info_list li .img {width: 100%; margin: 0;}
    .contact_page_section .contact_inner .contact_info .contact_info_list li .text {width: 100%; margin-top: 10px;}
    
    .map_section {margin: 15px 0;}

}

@media screen and (max-width:640px) {
    .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
    .bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    .query_section .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}

}

@media screen and (max-width:479px) {

    /* about app section */
    .about_app_section .about_img::before {left: 49%;}
}



`
